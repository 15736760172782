var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-row", [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "m-r-30",
                attrs: { type: "primary", icon: "el-icon-plus", size: "mini" },
                on: { click: _vm.handleBtn }
              },
              [_vm._v("上传固件")]
            )
          ],
          1
        )
      ]),
      _c(
        "el-row",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: { data: _vm.dataList, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "名称",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "platform",
                  label: "平台",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "code",
                  label: "特征码",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "board",
                  label: "Board ID",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "file_size",
                  label: "文件大小",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "file_verify",
                  label: "文件校验码",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "created_at",
                  label: "创建时间",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: "备注",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("a", { attrs: { title: scope.row.remark } }, [
                          _vm._v(_vm._s(scope.row.remark))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-opera" },
                          [
                            _c(
                              "el-tooltip",
                              { attrs: { content: "删除", placement: "top" } },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "delete" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.handleDel(scope.row)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totalPage > 0,
            expression: "totalPage>0"
          }
        ],
        attrs: {
          total: _vm.totalPage,
          page: _vm.searchForm.page,
          limit: _vm.searchForm.per_page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchForm, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchForm, "per_page", $event)
          },
          pagination: _vm.getTableData
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.uploadDialog, title: "上传固件" },
          on: {
            "update:visible": function($event) {
              _vm.uploadDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "right", "label-width": "70px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上传固件" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadFile",
                      attrs: {
                        action: _vm.uploadOption.uploadUrl,
                        headers: _vm.uploadOption.uploadHeader,
                        name: _vm.uploadOption.uploadName,
                        data: _vm.dialogForm,
                        multiple: false,
                        "auto-upload": false,
                        "show-file-list": true,
                        "on-success": _vm.handleUploadSu,
                        "file-list": _vm.fileList,
                        "on-change": _vm.handleChange,
                        "on-error": _vm.handleError
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            type: "primary"
                          },
                          on: { click: _vm.handleClearFile },
                          slot: "trigger"
                        },
                        [_vm._v("选取文件")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "500px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.dialogForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "remark", $$v)
                      },
                      expression: "dialogForm.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer-container" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleUpload } },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.uploadDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }