//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchEquWareList, uploadEquWare, delEquWare } from "@/api/charge";
import store from "@/store";
import Pagination from "@/components/Pagination";
export default {
  name: "wareList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      submitLoading: false,
      selectCount: 0,
      selectList: [],
      // upload
      uploadDialog: false,
      uploadOption: {
        uploadUrl: process.env.VUE_APP_URL + "/firmwares",
        uploadHeader: {
          Authorization: "bearer " + store.getters.token
        },
        uploadName: "file",
        limit: 1
      },
      fileList: [],
      // dialog form
      dialogForm: {
        remark: ""
      }
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      fetchEquWareList(this.searchForm).then(function (response) {
        console.log(response);
        _this.tableLoading = false;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log("页数");
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleClearFile: function handleClearFile() {
      console.log("选取");
      this.$refs.uploadFile.clearFiles();
    },
    handleUploadSu: function handleUploadSu(res, file) {
      console.log("success upload");
      this.uploadDialog = false;
      this.$message({
        type: "success",
        message: "上传成功"
      });
    },
    handleError: function handleError(res, file) {
      console.log("上传失败");
      console.log(res, file);
      this.$message({
        type: "error",
        message: res
      });
    },
    handleUpload: function handleUpload() {
      console.log("上传提交");
      this.$refs.uploadFile.submit();
      this.handlePageCurrent(1);
    },
    handleDel: function handleDel(row) {
      var _this2 = this;

      console.log("del", row);
      var data = {
        firmware_id: row.id
      };
      delEquWare(data).then(function (res) {
        console.log(res);

        _this2.$message({
          type: "success",
          message: "删除固件成功"
        });

        var index = _this2.dataList.indexOf(row);

        _this2.dataList.splice(index, 1);
      }).catch(function (err) {
        _this2.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleChange: function handleChange(file, fileList) {
      console.log(file, fileList);
    },
    handleBtn: function handleBtn() {
      this.uploadDialog = true;
      this.fileList = [];
    }
  }
};